import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Dictionaries`}</h1>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1U2U0RfJTkzgz_7-ykx2NgImvoOhtYu6P"
      }}>{`Download: Oxford Concise dictionary of mathematics by Christopher Clapham and James Nicholson`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1SS9jDskG8QoeutjoVYUQDbfDZxZ81jKN"
      }}>{`Download: Dictionary of mathematics terms by Douglas Downing `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1qdFaX890rzxS1WsfH3Inm1zM23Z75CYs"
      }}>{`Download: Dictionary of civil engineering by Jean Paul Kurtz`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1yua48YzNTdV2lEALpqqZ76uXBTn-Y7lv"
      }}>{`Download: Environmental engineering dictionary and directory by Thomas M. Pankratz `}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      